@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&family=Roboto:wght@100;400&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Cambios en el header: se utiliza el color más oscuro (#2A3663) para el fondo */
.header {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #2A3663;
  padding: 20px;
  width: 100%;
  /* border-radius: 5px; */
}

.header h1 {
  color: #FAF6E3; /* Se usa el color claro para el texto */
  font-family: 'Oswald', sans-serif;
  margin-bottom: 10px;
}

.header p {
  color: #FAF6E3;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
}

/* Calendario y leyenda: se deja igual */
.calendarSection {
  width: 100vw;
  margin-top: 20px;
  h1 {
    text-align: center;
  }
  height: 90vh;
}

.customtoolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

/* Botón custom: se cambia el fondo a uno de la paleta (#B59F78) */
.custombutton {
  background-color: #B59F78;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 12px;
}

.customtoolbarlabel {
  font-size: 20px;
  font-weight: bold;
}

.middle {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Estilos base - Mobile First para el formulario
   Se actualiza el background y algunos detalles para utilizar la nueva paleta:
   - Fondo: color muy claro (#FAF6E3)
   - Sombra y contornos se mantienen para buena legibilidad */
.formulario {
  width: 100%;
  padding: 15px;
  background-color: #FAF6E3;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 100px 0;
}

.formulario h1 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px;
  color: #2A3663;
}

.formulario form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.formulario label {
  font-size: 14px;
  color: #2A3663;
}

.formulario input[type="text"],
.formulario input[type="date"],
.formulario select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  background-color: #fff;
  transition: border-color 0.3s ease-in-out;
}

.formulario input[type="text"]:focus,
.formulario input[type="date"]:focus,
.formulario select:focus {
  border-color: #B59F78; /* Usamos uno de la paleta para enfocar */
  outline: none;
}

.formulario input[type="radio"] {
  margin-right: 5px;
}

.radio {
  display: flex;
  width: 100%;
  justify-content: center;
}

.formulario button {
  padding: 12px;
  background-color: #B59F78;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.formulario button:hover {
  background-color: #2A3663; /* Un color más oscuro para el hover */
}

.formulario form select {
  padding: 10px;
  border-radius: 5px;
}

.formulario button:active {
  background-color: #2A3663;
}

.formulario form input[type="radio"] + label {
  margin-right: 10px;
  font-size: 14px;
}

/* Para inputs deshabilitados, se respeta el estilo (sin utilizar la paleta para mantener la consistencia en interacción) */
input:disabled {
  background-color: #e0e0e0; /* Color gris claro */
  color: #8a8a8a;
  border: 1px solid #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}

input:disabled::placeholder {
  color: #b0b0b0;
}

/* Indicadores (leyenda debajo del calendario) - NO se modifican */
.indicaters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 0 10px 0;
}

.indicaters div{
  display: flex;
  align-items: center;
}

.ball {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
}

.available {
  background-color: gray;
}

.notAvailable {
  background-color: red;
}

.consequent {
  background-color: green;
}

.subsequent {
  background-color: orange;
}

/* Media Queries para pantallas más grandes */
@media (min-width: 768px) {
  .middle {
    flex-direction: row;
  }

  .middle section:first-child {
    width: 65%;
    padding: 0 2%;
  }
  
  .formulario {
    z-index: 1;
    padding: 20px;
    max-width: 500px;
    margin: 20px auto;
    align-self: center;
  }

  .formulario h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .formulario label {
    font-size: 16px;
  }

  .formulario input[type="text"],
  .formulario input[type="date"],
  .formulario select {
    font-size: 16px;
  }

  .formulario button {
    font-size: 16px;
  }

  .indicaters {
    display: none;
  }

  .calendarSection {
    width: 90vw;
    margin-bottom: 50px;
    height: auto;
  }
}
