/* 
   ---------------------------------------------------------
   Paleta de colores a utilizar:
   #FAF6E3 (más claro)
   #D8DBBD
   #B59F78
   #2A3663 (más oscuro)
   ---------------------------------------------------------
*/

/*TODO:AGREGAR/CAMBIAR PALETA DE COLORES */
/*TODO: LIMITAR TAMAÑO DE CALENDARIO PARA PC */

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&family=Roboto:wght@100;400&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
}

/* --- Ajustes generales del body --- */
body {
  background-color: #FAF6E3; /* Fondo claro */
  font-family: 'Roboto', sans-serif;
  color: #2A3663; /* Texto base en tono oscuro */
}

/* --- Barra de herramientas personalizada --- */
.customtoolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.custombutton {
  background-color: #2A3663; /* Botón oscuro */
  color: #FAF6E3;            /* Texto claro */
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 12px;
}

.customtoolbarlabel {
  font-size: 20px;
  font-weight: bold;
  color: #B59F78; /* Para resaltar el título de la toolbar */
}

/* --- Calendario (NO MODIFICAR colores internos) --- */
.calendar {
  overflow: hidden;
  text-align: center;
  max-width: 100%;
  margin-bottom: 20px;
  h1 {
    margin-bottom: 10px;
  }
}

.calendar .rbc-event {
  position: unset !important;
}

/* --- Botón adicional (fuera del calendario) --- */
.btn {
  margin-top: 1em;
  padding: 1em;
  border-radius: 5px;
  border: none;
  background: #2A3663; /* Oscuro */
  color: #FAF6E3;
  font-size: 1em;
  cursor: pointer;
}

/* --- Encabezado --- */
header {
  background-color: #2A3663; /* Encabezado oscuro */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
}

header p {
  color: #FAF6E3; /* Texto claro sobre fondo oscuro */
  font-size: 18px;
  margin-bottom: 5px;
  font-family: monospace;
}

/* --- Contenido principal --- */
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info a {
  text-decoration: none;
  color: #2A3663; /* Texto en color oscuro */
  font-size: 20px;
  margin: 0 10px;
}

.info img {
  width: 70px;
}

/* --- Título principal --- */
.title h1 {
  font-family: 'Oswald', sans-serif;
  color: #FAF6E3;  /* Contrasta con el header oscuro */
}

/* --- Sección s1 --- */
.s1 img {
  width: 100%;
}

.s1 div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

.s1 h2 {
  margin-bottom: 25px;
  text-align: center;
  font-size: 28px;
  color: #2A3663;
}

.s1 h2:first-of-type {
  display: none; /* Móvil: escondido */
}

.s1 p:first-of-type {
  text-align: center;
  margin-bottom: 25px;
  font-family: 'Roboto';
  line-height: 1.5;
  font-size: 20px;
  letter-spacing: 2px;
  color: #2A3663;
}

.s1 p:nth-of-type(2),
.s1 p:nth-of-type(3) {
  text-align: center;
  color: #2A3663;
}

.s1 span {
  font-size: 50px;
  color: #B59F78; /* Otro tono */
}

/* --- Sección s2 --- */
.s2 div {
  background-color: #B59F78; /* Fondo medio */
  padding: 25px;
  margin: 25px;
  line-height: 2;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.s2 h2 {
  text-align: center;
  font-size: 26px;
  color: #2A3663;
}

.s2 p {
  font-size: 20px;
  font-family: Arial;
  color: #2A3663;
}

.s2 img {
  width: 100%;
}

/* --- Sección s3 --- */
.s3 {
  margin-top: 25px;
}

.s3 div {
  background-color: #D8DBBD;
  padding: 20px;
  margin-bottom: 25px;
  border-radius: 3px;
  line-height: 1.8;
  width: 90vw;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
}

.s3 h2 {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid #2A3663;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  color: #2A3663;
}

.s3 p {
  font-size: 18px;
  font-family: Arial;
  color: #2A3663;
}

.s3 .subt,
.s3 .subt2 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: bold;
  color: #2A3663;
}

.s3 .imgs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 1px solid #2A3663;
}

.imgs img {
  width: 80px;
  height: 80px;
}

.s3 .map {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #2A3663;
  padding: 20px 0;
}

.map img {
  width: 60px;
  height: 80px;
}

.doctoralia {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.doctoralia img {
  width: 125px;
  height: 125px;
}

/* --- Sección s4 --- */
.s4 {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em 0;
}

.s4 h2 {
  text-align: center;
  font-size: 28px;
  color: #2A3663;
  margin-bottom: 20px;
  font-family: 'Oswald', sans-serif;
}

.s4 h1 {
  font-family: 'Oswald', sans-serif;
  font-size: 30px;
  margin-bottom: 20px;
  padding: 10px;
  letter-spacing: 2px;
  background: #D8DBBD; /* Fondo claro */
  border-radius: 5px;
  text-align: center;
  color: #2A3663;
}

.s4 div div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 2em 0;
  padding: 20px;
  background-color: #D8DBBD;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  justify-content: space-between;
}

.s4 iframe {
  width: 100%;
  max-width: 600px;
  height: 340px;
  border: none;
  border-radius: 10px;
}

/* --- Pie de página --- */
footer {
  width: 100%;
  height: 35px;
  background-color: #B59F78;
  text-align: center;
  padding-top: 10px;
  font-family: sans-serif;
  color: #2A3663;
}

/* --- Leyenda e indicadores (NO MODIFICAR) --- */
.indicaters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 0 10px 0;
}

.indicaters div {
  display: flex;
  align-items: center;
}

.ball {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
}

.available {
  background-color: gray;
}

.notAvailable {
  background-color: red;
}

.consequent {
  background-color: green;
}

.subsequent {
  background-color: orange;
}

/* --- Responsividad --- */
@media screen and (min-width: 1080px) {
  header {
    flex-direction: row;
    min-height: 10vh;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .info {
    width: 100%;
    justify-content: center;
  }
  
  .title {
    margin-left: 25px;
    margin-right: 100px;
  }
  
  header p {
    margin-top: 10px;
  }
  
  .s1 {
    display: flex;
    max-width: 1000px;
    margin-top: 30px;
  }
  
  .s1 img {
    width: 450px;
    padding: 30px;
  }
  
  .s1 div {
    padding: 0;
    max-width: 450px;
  }
  
  .s1 h2:first-of-type {
    display: block; /* Se muestra en pantallas grandes */
  }
  
  .s1 p:first-of-type {
    padding-bottom: 10px;
    border-bottom: 1px solid #2A3663;
  }
  
  .s2 {
    display: flex;
    max-width: 1000px;
    margin-top: 30px;
    border-bottom: 3px solid #2A3663;
  }
  
  .s2 img {
    width: 450px;
    padding: 30px;
  }
  
  .s2 div {
    width: 400px;
    padding-bottom: 0;
  }
  
  .s2 p {
    font-size: 22px;
  }
  
  .s3 {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 50px;
  }
  
  .s3 div {
    max-width: 400px;
  }
  
  .s4 div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 1000px;
  }
  
  .s4 div div {
    height: 500px;
    margin: 25px;
    max-width: 400px;
  }
  
  .indicaters {
    display: none; /* Se oculta la leyenda en pantallas grandes */
  }
  
  .calendar .container {
    width: 90vw;
  }
}