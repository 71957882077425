/* Fondo oscuro semitransparente */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo ligeramente menos oscuro */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 10px; /* Espaciado para evitar bordes ajustados */
    box-sizing: border-box;
}

/* Contenedor principal del modal */
.content {
    background-color: #ffffff;
    border-radius: 8px; /* Bordes más suaves */
    width: 90%;
    max-width: 380px;
    max-height: 90vh; /* Limita la altura máxima del modal */
    overflow-y: auto; /* Permite desplazarse si el contenido es más grande */
    padding: 20px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Sombra más suave */
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    transition: transform 0.3s ease-in-out; /* Agrega una animación suave */
}

/* Botón de cierre */
.closeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ff6b6b;
    border: none;
    border-radius: 50%;
    color: white;
    width: 28px;
    height: 28px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.closeButton:hover {
    background-color: #e55d5d; /* Un poco más oscuro al pasar el ratón */
}

/* Título */
h2 {
    margin: 0;
    text-align: center;
    color: #333;
    font-size: 20px;
}

/* Grupos de información */
.infoGroup {
    display: flex;
    align-items: center;
    gap: 12px;
}

.infoGroup label {
    flex: 1;
    font-weight: 600;
    color: #444;
    font-size: 14px;
}

.infoGroup input,
.infoGroup textarea {
    flex: 2;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 10px;
    font-size: 14px;
    color: #333;
    transition: border-color 0.2s ease;
}

textarea {
    height: 60px;
}

input:disabled,
textarea:disabled {
    color: #bbb;
    cursor: not-allowed;
}

/* Cuadro de color */
.colorBox {
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* Botones de acción */
.actions {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-top: auto; /* Garantiza que los botones se mantengan en la parte inferior */
}

button {
    flex: 1;
    padding: 12px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
    transform: scale(1.05); /* Efecto de agrandado al pasar el ratón */
}

.deleteButton {
    background-color: #ff6b6b;
    color: #fff;
}

.deleteButton:hover {
    background-color: #e55d5d;
}

.editButton {
    background-color: #4caf50;
    color: #fff;
}

.editButton:hover {
    background-color: #45a049;
}

/* Modal de confirmación */
.confirmation {
    text-align: center;
}

.confirmationActions {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.confirmButton,
.cancelButton {
    padding: 12px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.confirmButton {
    background-color: #ff6b6b;
    color: white;
}

.confirmButton:hover {
    background-color: #e55d5d;
}

.cancelButton {
    background-color: #ddd;
    color: #333;
}

.cancelButton:hover {
    background-color: #ccc;
}

/* Media Queries */
@media (min-width: 768px) {
    .content {
        max-width: 500px;
        padding: 25px;
    }

    .infoGroup label {
        font-size: 16px;
    }

    input,
    textarea {
        font-size: 16px;
        padding: 12px;
    }

    button {
        font-size: 16px;
    }
}
