@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&family=Roboto:wght@100;400&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
 
}

.backButton {
  display: inline-block;
  background-color: #33a3ff; /* Color de fondo */
  color: white; /* Color del texto */
  text-decoration: none; /* Sin subrayado */
  padding: 10px 15px; /* Espaciado interno */
  border-radius: 5px; /* Bordes redondeados */
  font-family: 'Roboto', sans-serif; /* Fuente */
  font-size: 14px; /* Tamaño de fuente */
  font-weight: 500; /* Peso de la fuente */
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
  position: absolute; /* Posicionamiento */
  top: 20px; /* Ajusta la posición vertical */
  left: 20px; /* Ajusta la posición horizontal */
}

.backButton:hover {
  background-color: #1e90ff; /* Color más oscuro para el hover */
  transform: scale(1.05); /* Efecto de aumento */
}

.backButton:active {
  background-color: #1c7ed6; /* Color al hacer clic */
  transform: scale(0.95); /* Efecto al presionar */
}



.header {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #3d9be9;
  padding: 20px;
  width: 100%;
  /* border-radius: 5px; */
}

.header h1 {
  color: white;
  font-family: 'Oswald', sans-serif;
  margin-bottom: 10px;
}

.header p {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
}

.calendarSection {
  width: 90vw;
  margin-top: 20px;
  margin-bottom: 50px;
  h1{
    text-align: center;
  }
  height: 80vh;
}
.customtoolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.custombutton {
  background-color: #2d3dcc;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 12px;
}

.customtoolbarlabel {
  font-size: 20px;
  font-weight: bold;
}


.middle {
  display: flex;
  flex-direction: column;
  width: 100%;
}


/* Estilos base - Mobile First */
.formulario {
  width: 100%;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.formulario h1 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
}

.formulario form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.formulario label {
  font-size: 14px;
  color: #555;
}

.formulario input[type="text"],
.formulario input[type="date"],
.formulario select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  background-color: #fff;
  transition: border-color 0.3s ease-in-out;
}

.formulario input[type="text"]:focus,
.formulario input[type="date"]:focus,
.formulario select:focus {
  border-color: #4CAF50;
  outline: none;
}

.formulario input[type="radio"] {
  margin-right: 5px;
}

.radio {
  display: flex;
  width: 100%;
  justify-content: center;
}

.formulario button {
  padding: 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.formulario button:hover {
  background-color: #45a049;
}

.formulario form select {
  padding: 10px;
  border-radius: 5px;
}

.formulario button:active {
  background-color: #3e8e41;
}

.formulario form input[type="radio"] + label {
  margin-right: 10px;
  font-size: 14px;
}

input:disabled {
  background-color: #e0e0e0; /* Color gris claro */
  color: #8a8a8a; /* Texto en gris */
  border: 1px solid #ccc; /* Borde suave */
  cursor: not-allowed; /* Cursor de "no permitido" */
  opacity: 0.6; /* Hace que se vea más tenue */
}

input:disabled::placeholder {
  color: #b0b0b0; /* Placeholder más claro para disabled */
}

.indicaters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 0 10px 0;
}

.indicaters div{
  display: flex;
  align-items: center;
}

.ball {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
}

.available {
  background-color: gray;
}

.notAvailable {
  background-color: red;
}

.consequent {
  background-color: green;
}

.subsequent {
  background-color: orange;
}


select#color option {
  font-weight: bold;
  padding: 5px;
}


/* Media Queries para pantallas más grandes */
@media (min-width: 768px) {
  .middle {
    flex-direction: row;
  }

  .middle section:first-child{
    width: 65%;
    padding: 0 2%;
  }
  
  .formulario {
    padding: 20px;
    max-width: 500px;
    margin: 20px auto;
    align-self: center;
  }

  .formulario h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .formulario label {
    font-size: 16px;
  }

  .formulario input[type="text"],
  .formulario input[type="date"],
  .formulario select {
    font-size: 16px;
  }

  .formulario button {
    font-size: 16px;
  }

  .indicaters {
    display: none;
}
}
