@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&family=Roboto:wght@100;400&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Encabezado */
.header {
  display: flex;
  flex-direction: row;
  text-align: center;
  background-color: #2A3663; /* Color de la paleta: color más oscuro */
  padding: 10px;
  width: 100%;
  justify-content: space-around;
  /* border-radius: 5px; */
}

.header h1 {
  color: #FAF6E3; /* Muy claro para contraste */
  font-family: 'Oswald', sans-serif;
  margin-bottom: 10px;
}

.header p {
  color: #FAF6E3;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
}

/* Sección del calendario */
.calendarSection {
  margin-top: 30px;  
}

.calendarSection h1 {
  text-align: center;
}

/* Barra de herramientas personalizada */
.customtoolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.custombutton {
  background-color: #2A3663; /* Mismo tono oscuro para botones */
  color: #FAF6E3;
  border: none;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 12px;
}

.customtoolbarlabel {
  font-size: 20px;
  font-weight: bold;
  color: #2A3663;
}

/* Contenedor central */
.middle {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Temporizador */
.timer {
  /* Gradiente usando dos tonalidades de la paleta: de acento a neutro medio */
  background: linear-gradient(135deg, #B59F78 0%, #D8DBBD 100%);
  color: #2A3663;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 1rem auto;
  max-width: 400px;
  transition: transform 0.3s ease;
}

.timer:hover {
  transform: scale(1.05);
}

.timer p {
  margin: 0;
  font-size: 1.2rem;
}

.timer strong {
  font-size: 1.4rem;
  font-weight: bold;
}

/* Estilos base - Mobile First para el formulario */
.formulario {
  width: 100%;
  padding: 15px;
  background-color: #FAF6E3; /* Fondo claro */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.formulario h1 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px;
  color: #2A3663;
}

.formulario form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.formulario label {
  font-size: 14px;
  color: #2A3663;
}

.formulario input[type="text"],
.formulario input[type="date"],
.formulario select {
  padding: 10px;
  border: 1px solid #B59F78;
  border-radius: 5px;
  font-size: 14px;
  background-color: #fff;
  transition: border-color 0.3s ease-in-out;
}

.formulario input[type="text"]:focus,
.formulario input[type="date"]:focus,
.formulario select:focus {
  border-color: #D8DBBD;
  outline: none;
}

.formulario input[type="radio"] {
  margin-right: 5px;
}

.radio {
  display: flex;
  width: 100%;
  justify-content: center;
}

.formulario button {
  padding: 12px;
  background-color: #B59F78;
  color: #FAF6E3;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.formulario button:hover {
  background-color: #D8DBBD;
}

.formulario form select {
  padding: 10px;
  border-radius: 5px;
}

.formulario button:active {
  background-color: #2A3663;
}

.formulario form input[type="radio"] + label {
  margin-right: 10px;
  font-size: 14px;
  color: #2A3663;
}

input:disabled {
  background-color: #D8DBBD;
  color: #2A3663;
  border: 1px solid #B59F78;
  cursor: not-allowed;
  opacity: 0.6;
}

input:disabled::placeholder {
  color: #B59F78;
}

/* Indicadores */
.indicaters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 0 10px 0;
}

.indicaters div {
  display: flex;
  align-items: center;
}

.ball {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
}

/* Se asignan los colores de la paleta o derivados para los indicadores */
.available {
  background-color: #D8DBBD;
}

.notAvailable {
  background-color: #B59F78;
}

.consequent {
  background-color: #2A3663;
}

.subsequent {
  background-color: #B59F78; /* Alternativa: puedes usar una variación o ajustar */
}

/* Botón de "Añadir cita" */
.addButton {
  background-color: #B59F78;
  color: #FAF6E3;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
}

.addButton:hover {
  background-color: #D8DBBD;
  transform: translateY(-2px);
}

.addButton:active {
  background-color: #2A3663;
  transform: translateY(0);
}

/* Media Queries para pantallas más grandes */
@media (min-width: 768px) {
  .middle {
    flex-direction: row;
  }

  .middle section:first-child {
    width: 65%;
    padding: 0 2%;
  }
  
  .formulario {
    padding: 20px;
    max-width: 500px;
    margin: 20px auto;
    align-self: center;
  }

  .formulario h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .formulario label {
    font-size: 16px;
  }

  .formulario input[type="text"],
  .formulario input[type="date"],
  .formulario select {
    font-size: 16px;
  }

  .formulario button {
    font-size: 16px;
  }

  .indicaters {
    display: none;
  }
}